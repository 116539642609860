import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const ongoingTemplateImportService = {
  create(claimGroupId, formData, type) {
    return fetch(`/api/claim_groups/${claimGroupId}/ongoing_template_imports?type=${type}`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData,
    });
  },
  delete(claimGroupId, id) { 
    return fetch(`/api/claim_groups/${claimGroupId}/ongoing_template_imports/${id}`, request.delete);
  }
}

export default ongoingTemplateImportService;