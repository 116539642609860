import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import LoginPage from '@/pages/LoginPage';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useDispatch } from 'react-redux';
import ClaimTemplate from '@/pages/admin/ClaimTemplate';
import KickoffEmailTemplatePage from '@/pages/admin/KickoffEmailTemplatePage';
import ClaimScheduleIcsEventTemplate from '@/pages/admin/ClaimScheduleIcsEventTemplatePage';
import { getCurrentSession } from '@rd-web-markets/shared/dist/store/features/authSlice';
import { completeLoading } from '@rd-web-markets/shared/dist/store/features/uiSlice';
import { AdminRoute } from '@components/util/AdminRoute';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import UserPage from '@/pages/admin/UserPage';
import RatesPage from '@/pages/admin/RatesPage';
import AllClaimsPage from '@/pages/admin/AllClaimsPage';
import DocumentsPage from '@/pages/admin/DocumentsPage';
import MyClaimsPage from '@/pages/admin/MyClaimsPage';
import FinancialDetailsPage from '@/pages/shared/financial_details/FinancialDetailsPage';
import CompanyPage from '@/pages/admin/CompanyPage';
import CompanyEditPage from '@/pages/admin/CompanyEditPage';
import ReportTemplatePage from '@/pages/admin/ReportTemplatePage';
import TechnicalProofPage from '@/pages/shared/technical_proof/TechnicalProofPage';
import ClaimProjectReportDetailPage from '@/pages/shared/technical_proof/ClaimProjectReportDetailPage';
import NewCompanyPage from '@/pages/admin/kimble/NewCompanyPage';
import NewCompanySelectClaimsPage from './pages/admin/kimble/NewCompanySelectClaimsPage';
import ClientCostTemplatePage from './pages/admin/ClientCostTemplatePage';
import CostTemplateUploadPage from './pages/CostTemplateUploadPage';
import ClaimEditGeneralPage from './pages/shared/claim/claimEditSection/general/ClaimEditGeneralPage';
import ClaimEditInternalInvoiceDetailsPage from './pages/shared/claim/claimEditSection/internalInvoiceDetails/ClaimEditInternalInvoiceDetailsPage';
import CreateClaimGroupReportTemplatePage from './pages/shared/claim_group/CreateClaimGroupReportTemplatePage';
import CreateReportTemplatePage from './pages/admin/CreateReportTemplatePage';
import EditReportTemplatePage from './pages/admin/EditReportTemplatePage';
import EditClaimGroupReportTemplatePage from './pages/shared/claim_group/EditClaimGroupReportTemplatePage';
import RndReportsPage from './pages/shared/report_template/RndReportsPage';
import CreateUserPage from './pages/admin/CreateUserPage';
import EditUserPage from './pages/admin/EditUserPage';
import { ClientRoute } from '@components/util/ClientRoute';
import ClientRndReportsPage from './pages/client/ClientRndReportsPage';
import TechnicalUploadsPage from './pages/admin/TechnicalUploadsPage';
import TechnicalUploadLinkPage from './pages/TechnicalUploadLinkPage';
import ClaimGroupOverviewPage from './pages/shared/claim_group/ClaimGroupOverviewPage';
import ClaimGroupMasterPage from './pages/shared/claim_group/ClaimGroupMasterPage';
import ClaimGroupTasksPage from './pages/shared/claim_group/tasks/ClaimGroupTasksPage';
import AddTaskPage from './pages/shared/claim_group/tasks/AddTaskPage';
import ClaimGroupEditGeneralPage from './pages/shared/claim_group/ClaimGroupEditGeneralPage';
import AddClaimToClaimGroupPage from './pages/shared/claim_group/AddClaimToClaimGroupPage';
import AddClaimGroupToCompanyPage from './pages/shared/company/AddClaimGroupToCompanyPage';
import ClaimGroupClaimSubmissionsPage from './pages/admin/ClaimGroupClaimSubmissionsPage';
import ClaimGroupKimbleInvoicesPage from './pages/admin/ClaimGroupKimbleInvoicesPage';
import AllClaimGroupsPage from './pages/shared/claim_group/AllClaimGroupsPage';
import MyClaimGroupsPage from './pages/admin/MyClaimGroupsPage';
import { ConsultantRoute } from '@components/util/ConsultantRoute';
import AllSumPage from './pages/shared/financial_details/AllSumPage';
import ClaimGroupEditAffiliatedCompaniesPage from './pages/shared/claim_group/ClaimGroupEditAffiliatedCompaniesPage';
import ClaimProjectReportFinancialPersonnelFrameworksPage from './pages/shared/technical_proof/ClaimProjectReportFinancialPersonnelFrameworksPage';
import ProjectsPage from './pages/shared/ProjectsPage';
import { Suspense } from 'react';
import { APP_FOOTER_TEXT } from '@rd-web-markets/market/dist/constants';
import DashboardPage from './pages/shared/DashboardPage';
import RegularRealtimeDashboardPage from './pages/shared/RegularRealtimeDashboardPage';
import TranslationsPage from './pages/TranslationsPage';
import ClientClaimGroupOverviewPage from './pages/shared/claim_group/client/ClientClaimGroupOverviewPage';
import ClaimGroupDashboardListPage from './pages/shared/claim_group/ClaimGroupDashboardListPage';
import CompanyDashboardListPage from './pages/shared/claim_group/CompanyDashboardListPage';
import { AccountantRoute } from '@components/util/AccountantRoute';
import FailedLoginPage from './pages/FailedLoginPage';
import DashboardAccountant from '@rd-web-markets/us/dist/pages/accountant/DashboardAccountant';
import ProjectOverviewPage from './pages/shared/claim_group/ProjectOverviewPage';
import ReleasesPage from './pages/shared/release_notes/ReleasesPage';
import NotesPage from './pages/shared/release_notes/NotesPage';
import TimeTrackingCompaniesPage from './pages/project_time_tracking/TimeTrackingCompaniesPage';
import CreateClaimProjectReportPage from './pages/project_time_tracking/claim_project_reports/CreateClaimProjectReportPage';
import TimeTrackingClaimGroupsPage from './pages/project_time_tracking/TimeTrackingClaimGroupsPage';
import TimeTrackingProjectsPage from './pages/project_time_tracking/TimeTrackingProjectsPage';
import MyTasksPage from './pages/project_time_tracking/MyTasksPage';
import TimeTrackingTasksPage from './pages/project_time_tracking/TimeTrackingTasksPage';
import TimeTrackingTaskFormPage from './pages/project_time_tracking/TimeTrackingTaskFormPage';
import EditClaimProjectReportPage from './pages/project_time_tracking/claim_project_reports/EditClaimProjectReportPage';
import QuestionnairesPage from './pages/shared/questionnaires/QuestionnairesPage';
import QuestionnaireFormPage from './pages/shared/questionnaires/QuestionnaireFormPage';
import ProjectReportQuestionnairesPage from './pages/shared/technical_proof/ProjectReportQuestionnairesPage';
import ProjectReportEditQuestionnairePage from './pages/shared/technical_proof/ProjectReportEditQuestionnairePage';
import FaqTemplatePage from './pages/admin/faq/FaqTemplatePage';
import CompanyGroupPage from './pages/admin/CompanyGroupPage';
import CompanyGroupEditPage from './pages/admin/CompanyGroupEditPage';
import CompanyGroupAddPage from './pages/admin/CompanyGroupAddPage';
import { ProtectedClaimGroupDescendantPage } from '@rd-web-markets/shared/dist/util/ProtectedClaimGroupDescendantPage';
import ProjectQuestionnaireTemplatePage from '@rd-web-markets/be/dist/pages/ProjectQuestionnaireTemplatePage';


let CreatingApplicationPage;
let CreateProjectReportContractorPage;
let EditProjectReportContractoPage;
let SubmitApplicationPage;
let FirstFastCheckPage;
let FurtherRequestsPage;
let BsfzCertificatePage;
let ObjectionPage;
let BsfzProjectOverviewPage;
let CustomerCreateProjectPage;
let SelfDeclarationEmailTemplatePage;
let ClientAllClaimsWithProjectProgressPage;
let SicCodePage;
let DataCentrePage;
let EmailNotificationTemplatesPage;
let ClaimGroupEditQuestionaireTemplatePage;
let ClaimGroupDashboardPage;


if(process.env.PUBLIC_URL === '/de'){
  CreatingApplicationPage = React.lazy(() => import('./pages/shared/technical_proof/germany/CreatingApplicationPage'));
  SubmitApplicationPage = React.lazy(() => import('./pages/shared/technical_proof/germany/SubmitApplicationPage'));
  CreateProjectReportContractorPage = React.lazy(() => import('./pages/shared/technical_proof/germany/CreateProjectReportContractorPage'));
  EditProjectReportContractoPage = React.lazy(() => import('./pages/shared/technical_proof/germany/EditProjectReportContractoPage'));
  FirstFastCheckPage = React.lazy(() => import('./pages/shared/technical_proof/germany/FirstFastCheckPage'));
  FurtherRequestsPage = React.lazy(() => import('./pages/shared/technical_proof/germany/FurtherRequestsPage'));
  BsfzCertificatePage = React.lazy(() => import('./pages/shared/technical_proof/germany/BsfzCertificatePage'));
  ObjectionPage = React.lazy(() => import('./pages/shared/technical_proof/germany/ObjectionPage'));
  BsfzProjectOverviewPage = React.lazy(() => import('./pages/shared/technical_proof/germany/BsfzProjectOverviewPage'));
}else if(process.env.PUBLIC_URL === '/it'){
  SelfDeclarationEmailTemplatePage = React.lazy(() => import('./pages/it/SelfDeclarationEmailTemplatePage'));
  ClientAllClaimsWithProjectProgressPage = React.lazy(() => import('@rd-web-markets/market/dist/pages/ClientAllClaimsWithProjectProgressPage'));
}else if(process.env.PUBLIC_URL === '/uk') {
  SicCodePage = React.lazy(() => import('./pages/uk/SicCodePage'));
  DataCentrePage = React.lazy(() => import('@rd-web-markets/market/dist/claim_group/data_centre/DataCentrePage'));
}else if(process.env.PUBLIC_URL === '/be') {
  CustomerCreateProjectPage = React.lazy(() => import('@rd-web-markets/market/dist/claim_project_reports/CustomerCreateProjectPage'));
  EmailNotificationTemplatesPage = React.lazy(() => import('@rd-web-markets/market/dist/pages/EmailNotificationTemplatesPage'));
  ClaimGroupEditQuestionaireTemplatePage = React.lazy(() => import('./pages/shared/claim_group/ClaimGroupEditQuestionaireTemplatePage'));
  ClaimGroupDashboardPage = React.lazy(() => import('./pages/shared/claim_group/ClaimGroupDashboardPage'));
}

const App = () => {
  const { isLoading } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const basename = process.env.PUBLIC_URL;

  useEffect(() => {
    fetch('/api/users/current_session', {})
      .then(response => {
        dispatch(getCurrentSession(response));
        dispatch(completeLoading(''));
      }).catch(error =>   dispatch(completeLoading(error.message)));
  });

  /**
   * Never use React.Fragment (<></>) or random pages will stop working.
   */
  return (
    <Router basename={basename}>
      {isLoading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/admin" />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/login_failed">
              <FailedLoginPage />
            </Route>
            <Route path="/claim_import_cost_template_infos/:import_id/one_time_link_uploads/:link_id">
              <CostTemplateUploadPage />
            </Route>
            <Route path="/technical_uploads/:technical_upload_id/one_time_link_uploads/:link_id">
              <TechnicalUploadLinkPage />
            </Route>
            <AdminRoute
              exact
              path="/admin"
              basename={basename}
              component={CompanyPage}
            />
            <AdminRoute
              exact
              path="/admin/company_groups"
              basename={basename}
              component={CompanyGroupPage}
            />
            <AdminRoute
              exact
              path="/admin/company_groups/create"
              basename={basename}
              component={CompanyGroupAddPage}
            />
            <AdminRoute
              exact
              path="/admin/company_groups/:company_group_id"
              basename={basename}
              component={CompanyGroupEditPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_template"
              component={ClaimTemplate}
            />
            <AdminRoute
              exact
              path="/admin/kickoff_email_template"
              basename={basename}
              component={KickoffEmailTemplatePage}
            />
            <AdminRoute
              exact
              path="/admin/claim_schedule_ics_event_template"
              basename={basename}
              component={ClaimScheduleIcsEventTemplate}
            />
            <AdminRoute
              exact
              path="/admin/client_cost_template"
              basename={basename}
              component={ClientCostTemplatePage}
            />
            {process.env.PUBLIC_URL === '/it' &&
              <AdminRoute
                exact
                path="/admin/self_declaration_email_template"
                basename={basename}
                component={SelfDeclarationEmailTemplatePage}
              />
            }
            <AdminRoute
              exact
              path="/admin/companies"
              basename={basename}
              component={CompanyPage}
            />
            <AdminRoute
              exact
              path="/admin/list_of_companies"
              basename={basename}
              component={TimeTrackingCompaniesPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/companies/:company_id/claim_groups/"
              basename={basename}
              component={TimeTrackingClaimGroupsPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/claim_groups/:claim_group_id/claim_project_reports/"
              basename={basename}
              component={TimeTrackingProjectsPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/claim_groups/:claim_group_id/claim_project_reports/:claim_project_report_id/tasks"
              basename={basename}
              component={TimeTrackingTasksPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/claim_groups/:claimGroupId/claim_project_reports/:claimProjectReportId/create_task"
              basename={basename}
              component={TimeTrackingTaskFormPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/claim_groups/:claimGroupId/claim_project_reports/:claimProjectReportId/edit_task/:taskId"
              basename={basename}
              component={TimeTrackingTaskFormPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/my_tasks/"
              basename={basename}
              component={MyTasksPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/my_tasks/claim_project_reports/:claimProjectReportId/create_task"
              basename={basename}
              component={TimeTrackingTaskFormPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/my_tasks/claim_project_reports/:claimProjectReportId/edit_task/:taskId"
              basename={basename}
              component={TimeTrackingTaskFormPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/create_project"
              basename={basename}
              component={CreateClaimProjectReportPage}
            />
            <AdminRoute
              exact
              path="/admin/time_tracking/claim_groups/:claim_group_id/claim_project_reports/:claim_project_report_id"
              basename={basename}
              component={EditClaimProjectReportPage}
            />
            {process.env.PUBLIC_URL === '/uk' && (
              <AdminRoute
                exact
                path='/admin/sic_codes'
                basename={basename}
                component={SicCodePage}
              />
            )}
            <AdminRoute
              exact
              path="/admin/companies/:id/edit"
              basename={basename}
              component={CompanyEditPage}
            />
            <AdminRoute
              exact
              path="/admin/report_templates"
              basename={basename}
              component={ReportTemplatePage}
            />
            <AdminRoute
              exact
              path="/admin/report_templates/create"
              basename={basename}
              component={CreateReportTemplatePage}
            />
            <AdminRoute
              exact
              path="/admin/report_templates/:id/edit"
              basename={basename}
              component={EditReportTemplatePage}
            />
            <AdminRoute
              exact
              path="/admin/report_templates/:report_template_id/rnd_reports"
              basename={basename}
              component={RndReportsPage}
            />
            <AdminRoute
              exact
              path="/admin/users"
              basename={basename}
              component={UserPage}
            />
            <AdminRoute
              exact
              path="/admin/users/new"
              basename={basename}
              component={CreateUserPage}
            />
            <AdminRoute
              exact
              path="/admin/users/:id/edit"
              basename={basename}
              component={EditUserPage}
            />
            <AdminRoute
              exact
              path="/admin/rates"
              basename={basename}
              component={RatesPage}
            />
            { !['/it', '/de'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/all_claims"
                basename={basename}
                component={AllClaimsPage}
              />
            }
            { ['/be', '/uk'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/documents"
                basename={basename}
                component={DocumentsPage}
              />
            }
            { !['/it', '/de'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/my_claims"
                basename={basename}
                component={MyClaimsPage}
              />
            }
            <AdminRoute
              exact
              path="/admin/my_claim_groups"
              basename={basename}
              component={MyClaimGroupsPage}
            />
            <AdminRoute
              exact
              path="/admin/companies/new"
              basename={basename}
              component={NewCompanyPage}
            />
            <AdminRoute
              exact
              path="/admin/companies/add_claim_group/:id_in_provider_system_and_companies_house_number/companies/:company_id"
              basename={basename}
              component={AddClaimGroupToCompanyPage}
            />
            <AdminRoute
              exact
              path="/admin/companies/new/:id_in_provider_system_and_companies_house_number"
              basename={basename}
              component={NewCompanySelectClaimsPage}
            />
            <AdminRoute
              exact
              path="/admin/companies/:company_id/master"
              basename={basename}
              component={ClaimGroupMasterPage}
            />
            <AdminRoute
              exact
              path="/admin/companies/:company_id/:claim_group_step"
              basename={basename}
              component={ClaimGroupOverviewPage}
            />
            <AdminRoute
              exact
              path="/admin/companies/:company_id/:claim_group_id/master"
              basename={basename}
              component={ClaimGroupMasterPage}
            />
            <AdminRoute
              exact
              path="/admin/companies/:company_id/:claim_group_id/:claim_group_step"
              basename={basename}
              component={ClaimGroupOverviewPage}
            />
            <AdminRoute
              exact
              path="/admin/companies/:company_id/:claim_group_id/:type/:page"
              basename={basename}
              component={AllSumPage}
            />
            <AdminRoute
              exact
              path="/admin/claims/:claim_id/edit/internal_invoice_detail"
              basename={basename}
              component={ClaimEditInternalInvoiceDetailsPage}
            />
            <AdminRoute
              exact
              path="/admin/claims/:claim_id/financial_details/:page"
              basename={basename}
              component={FinancialDetailsPage}
            />
            <AdminRoute
              exact
              path="/admin/claims/:claim_id/projects/:project_id/financial_details/:page"
              basename={basename}
              component={FinancialDetailsPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/claim_submissions"
              basename={basename}
              component={ClaimGroupClaimSubmissionsPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/kimble_invoices"
              basename={basename}
              component={ClaimGroupKimbleInvoicesPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/project_overview"
              basename={basename}
              component={ProjectOverviewPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/data_centre/:dataCentreSubPage"
              basename={basename}
              component={DataCentrePage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/technical_proof/:page"
              basename={basename}
              component={TechnicalProofPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id"
              basename={basename}
              component={ClaimProjectReportDetailPage}
            />

            {process.env.PUBLIC_URL === '/it' && (
              <AdminRoute
                exact
                path='/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/documentation/:documentation_type'
                basename={basename}
                component={ClaimProjectReportDetailPage}
              />
            )}
            {process.env.PUBLIC_URL === '/it' && (
              <AdminRoute
                exact
                path='/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/technical_analysis/'
                basename={basename}
                component={ClaimProjectReportDetailPage}
              />
            )}
            {process.env.PUBLIC_URL === '/it' && (
              <AdminRoute
                exact
                path='/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/self_declaration_email/'
                basename={basename}
                component={ClaimProjectReportDetailPage}
              />
            )}

            {process.env.PUBLIC_URL === '/it' && (
              <AdminRoute
                exact
                path='/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/payment_and_invoices/:direct_purchase_lease'
                basename={basename}
                component={ClaimProjectReportDetailPage}
              />
            )}

            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/creating_application/:page"
                basename={basename}
                component={CreatingApplicationPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/submit_application/:page"
                basename={basename}
                component={SubmitApplicationPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_project_overview"
                basename={basename}
                component={BsfzProjectOverviewPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/additional_informations/:id"
                basename={basename}
                component={FurtherRequestsPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_certificates/:id"
                basename={basename}
                component={BsfzCertificatePage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/objection"
                basename={basename}
                component={ObjectionPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/first_fast_check"
                basename={basename}
                component={FirstFastCheckPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/claim_project_reports/:reportId/project_contractors/new"
                basename={basename}
                component={CreateProjectReportContractorPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/claim_project_reports/:reportId/project_contractors/:contractorId"
                basename={basename}
                component={EditProjectReportContractoPage}
              />
            }
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/frameworks"
              basename={basename}
              component={ClaimProjectReportFinancialPersonnelFrameworksPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claim_group_id/report_templates/create"
              basename={basename}
              component={CreateClaimGroupReportTemplatePage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claim_group_id/report_templates/:id/edit"
              basename={basename}
              component={EditClaimGroupReportTemplatePage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/edit/tasks"
              basename={basename}
              component={ClaimGroupTasksPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/add/task"
              basename={basename}
              component={AddTaskPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/edit/task/:taskId"
              basename={basename}
              component={AddTaskPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/edit/general"
              basename={basename}
              component={ClaimGroupEditGeneralPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/technical_proof/affiliated_companies"
              basename={basename}
              component={ClaimGroupEditAffiliatedCompaniesPage}
            />
            <AdminRoute
              exact
              path="/admin/all_claim_groups"
              basename={basename}
              component={AllClaimGroupsPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claimGroupId/technical_uploads"
              basename={basename}
              component={TechnicalUploadsPage}
            />
            <AdminRoute
              exact
              path="/admin/add_claim/company/:id_in_provider_system/claim_groups/:claimGroupId"
              basename={basename}
              component={AddClaimToClaimGroupPage}
            />
            <AdminRoute
              exact
              path="/admin/claims/:claim_id/edit/general"
              basename={basename}
              component={ClaimEditGeneralPage}
            />
            <AdminRoute
              exact
              path="/admin/projects/all"
              basename={basename}
              component={ProjectsPage}
            />
            <AdminRoute
              exact
              path="/admin/projects/my"
              basename={basename}
              component={ProjectsPage}
            />
            <AdminRoute
              exact
              path="/admin/translations"
              basename={basename}
              component={TranslationsPage}
            />

            { ['/uk', '/us'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/dashboards/"
                basename={basename}
                component={CompanyDashboardListPage}
              />
            }

            { ['/uk', '/us'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/dashboard/:company_id"
                basename={basename}
                component={DashboardPage}
              />
            }
            { ['/uk'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/regular_realtime_dashboard/:company_id"
                basename={basename}
                component={RegularRealtimeDashboardPage}
              />
            }
            <AdminRoute
              exact
              path="/admin/release_notes"
              basename={basename}
              component={ReleasesPage}
            />
            <AdminRoute
              exact
              path="/admin/release_notes/:release_id"
              basename={basename}
              component={NotesPage}
            />
            <AdminRoute
              exact
              path="/admin/questionnaires"
              basename={basename}
              component={QuestionnairesPage}
            />
            <AdminRoute
              exact
              path={['/admin/questionnaires/create', '/admin/questionnaires/:id/edit']}
              basename={basename}
              component={QuestionnaireFormPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires"
              basename={basename}
              component={ProjectReportQuestionnairesPage}
            />
            <AdminRoute
              exact
              path="/admin/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires/:id"
              basename={basename}
              component={ProjectReportEditQuestionnairePage}
            />

            <AdminRoute
              exact
              path="/admin/faq_template"
              basename={basename}
              component={FaqTemplatePage}
            />

            { ['/be'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/mission_email_notification_templates"
                basename={basename}
                component={EmailNotificationTemplatesPage}
              />
            }
            { ['/be'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/edit/questionaire"
                basename={basename}
                component={ClaimGroupEditQuestionaireTemplatePage}
              />
            }
            { ['/be'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/claim_groups/:claimGroupId/claim_group_dashboard"
                basename={basename}
                component={ClaimGroupDashboardPage}
              />
            }
            {
              ['/be'].includes(process.env.PUBLIC_URL) &&
              <AdminRoute
                exact
                path="/admin/project_questionnaire_template"
                basename={basename}
                component={ProjectQuestionnaireTemplatePage}
              />
            }
{/* 
            <AccountantRoute
              exact
              path="/accountant"
              basename={basename}
              component={MyClaimGroupsPage}
            />

            { ['/us'].includes(process.env.PUBLIC_URL) &&
              <AccountantRoute
                exact
                path="/accountant/dashboard"
                basename={basename}
                component={DashboardAccountant}
              />
            }
            <AccountantRoute
              exact
              path="/accountant/companies/:company_id/:claim_group_id"
              basename={basename}
              component={ClientClaimGroupOverviewPage}
            />
            <AccountantRoute
              exact
              path="/accountant/companies"
              basename={basename}
              component={MyClaimGroupsPage}
            />
            <AccountantRoute
              exact
              path={['/accountant/my_claim_groups']}
              basename={basename}
              component={MyClaimGroupsPage}
            />
            <AccountantRoute
              exact
              path={['/accountant/projects/my', '/super_consultant/projects/all']}
              basename={basename}
              component={ProjectsPage}
            />
            <AccountantRoute
              exact
              path="/accountant/claims/:claim_id/financial_details/:page"
              basename={basename}
              component={FinancialDetailsPage}
            />
            <AccountantRoute
              exact
              path="/accountant/report_template/:report_template_id"
              basename={basename}
              component={ClientRndReportsPage}
            />
            <AccountantRoute
              exact
              path="/accountant/release_notes"
              basename={basename}
              component={ReleasesPage}
            />
            <AccountantRoute
              exact
              path="/accountant/release_notes/:release_id"
              basename={basename}
              component={NotesPage}
            /> */}

            {process.env.PUBLIC_URL === '/it' ?
              <ClientRoute
                exact
                path="/customer"
                basename={basename}
                component={ClientAllClaimsWithProjectProgressPage}
              /> :
              <ClientRoute
                exact
                path="/customer"
                basename={basename}
                component={MyClaimGroupsPage}
              />
            }
            <ClientRoute
              exact
              path="/customer/companies/:company_id/master"
              basename={basename}
              component={MyClaimGroupsPage}
            />
            { ['/us', '/ca', '/de', '/uk', '/be'].includes(process.env.PUBLIC_URL) &&
              <ClientRoute
                exact
                path="/customer/companies"
                basename={basename}
                component={MyClaimGroupsPage}
              />
            }
            <ClientRoute
              exact
              path="/customer/report_template/:report_template_id"
              basename={basename}
              component={ClientRndReportsPage}
            />
            <ClientRoute
              exact
              path="/customer/claim_groups/:claimGroupId/project_overview"
              basename={basename}
              component={ProjectOverviewPage}
            />
            <ClientRoute
              exact
              path="/customer/companies/:company_id/:claim_group_id"
              basename={basename}
              component={ClientClaimGroupOverviewPage}
            />
            <ClientRoute
              exact
              path="/customer/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id"
              basename={basename}
              component={ClaimProjectReportDetailPage}
              pageName={'ClaimProjectReportDetailPage'}
            />
            <ClientRoute
              exact
              path="/customer/claims/:claim_id/financial_details/:page"
              basename={basename}
              component={FinancialDetailsPage}
            />
            { ['/be'].includes(process.env.PUBLIC_URL) &&
              <ClientRoute
                exact
                path="/customer/claim_groups/:claimGroupId/edit/general"
                basename={basename}
                component={ClaimGroupEditGeneralPage}
              />
            }
            { ['/be'].includes(process.env.PUBLIC_URL) && 
              <ClientRoute
                exact
                path="/customer/claim_groups/:claim_group_id/create_project"
                basename={basename}
                component={CustomerCreateProjectPage}
              />
            }
            { ['/uk'].includes(process.env.PUBLIC_URL) && 
              <ClientRoute
                exact
                path="/customer/companies/:company_id/:claim_group_id/:claim_group_step"
                basename={basename}
                component={ClaimGroupOverviewPage}
              />
            }
            { ['/it'].includes(process.env.PUBLIC_URL) &&
              <ClientRoute
                exact
                path="/customer/dashboards/"
                basename={basename}
                component={ClaimGroupDashboardListPage}
              />
            }
            { ['/uk', '/us'].includes(process.env.PUBLIC_URL) &&
              <ClientRoute
                exact
                path="/customer/dashboards/"
                basename={basename}
                component={CompanyDashboardListPage}
              />
            }
            { ['/uk', '/us'].includes(process.env.PUBLIC_URL) &&
              <ClientRoute
                exact
                path="/customer/dashboard/:company_id"
                basename={basename}
                component={DashboardPage}
              />
            }
            { ['/uk'].includes(process.env.PUBLIC_URL) &&
              <ClientRoute
                exact
                path="/customer/regular_realtime_dashboard/:company_id"
                basename={basename}
                component={RegularRealtimeDashboardPage}
              />
            }
            { ['/it'].includes(process.env.PUBLIC_URL) &&
              <ClientRoute
                exact
                path="/customer/dashboard/:claim_group_id"
                basename={basename}
                component={DashboardPage}
              />
            }
            <ClientRoute
              exact
              path="/customer/release_notes"
              basename={basename}
              component={ReleasesPage}
            />
            <ClientRoute
              exact
              path="/customer/release_notes/:release_id"
              basename={basename}
              component={NotesPage}
            />
            <ClientRoute
              exact
              path="/customer/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires"
              basename={basename}
              component={ProjectReportQuestionnairesPage}
            />
            <ClientRoute
              exact
              path="/customer/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires/:id"
              basename={basename}
              component={ProjectReportEditQuestionnairePage}
            />

            { ['/uk'].includes(process.env.PUBLIC_URL) &&
              <ConsultantRoute
                exact
                path={['/consultant/dashboards', '/super_consultant/dashboards']}
                basename={basename}
                component={CompanyDashboardListPage}
              />
            }

            <ConsultantRoute
              exact
              path={['/consultant', '/super_consultant']}
              basename={basename}
              component={CompanyPage}
            />
            { !['/it', '/de'].includes(process.env.PUBLIC_URL) &&
              <ConsultantRoute
                exact
                path={['/consultant/my_claims', '/super_consultant/my_claims']}
                basename={basename}
                component={MyClaimsPage}
              />
            }
            { !['/it', '/de'].includes(process.env.PUBLIC_URL) &&
              <ConsultantRoute
                exact
                path={['/super_consultant/all_claims']}
                basename={basename}
                component={AllClaimsPage}
              />
            }
            <ConsultantRoute
              exact
              path="/super_consultant/all_claim_groups"
              basename={basename}
              component={AllClaimGroupsPage}
            />
            <ConsultantRoute
              exact
              path={['/consultant/my_claim_groups', '/super_consultant/my_claim_groups']}
              basename={basename}
              component={MyClaimGroupsPage}
            />
            <ConsultantRoute
              exact
              path={['/consultant/projects/all', '/super_consultant/projects/all']}
              basename={basename}
              component={ProjectsPage}
            />
            <ConsultantRoute
              exact
              path={['/consultant/projects/my', '/super_consultant/projects/my']}
              basename={basename}
              component={ProjectsPage}
            />
            <ConsultantRoute
              exact
              path={['/consultant/companies', '/super_consultant/companies']}
              basename={basename}
              component={CompanyPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/companies/add_claim_group/:id_in_provider_system_and_companies_house_number/companies/:company_id',
                '/super_consultant/companies/add_claim_group/:id_in_provider_system_and_companies_house_number/companies/:company_id'
              ]}
              basename={basename}
              component={AddClaimGroupToCompanyPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/add_claim/company/:id_in_provider_system/claim_groups/:claimGroupId',
                '/super_consultant/add_claim/company/:id_in_provider_system/claim_groups/:claimGroupId'
              ]}
              basename={basename}
              component={AddClaimToClaimGroupPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claimGroupId/edit/general',
                '/super_consultant/claim_groups/:claimGroupId/edit/general'
              ]}
              basename={basename}
              component={ClaimGroupEditGeneralPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claims/:claim_id/financial_details/:page',
                '/super_consultant/claims/:claim_id/financial_details/:page'
              ]}
              basename={basename}
              component={FinancialDetailsPage}
            />
            <ConsultantRoute
              exact
              path={['/consultant/companies/new', '/super_consultant/companies/new']}
              basename={basename}
              component={NewCompanyPage}
            />
            <ConsultantRoute
              exact
              path={["/consultant/company_groups", "/super_consultant/company_groups"]}
              basename={basename}
              component={CompanyGroupPage}
            />
            <ConsultantRoute
              exact
              path={["/consultant/company_groups/create", "/super_consultant/company_groups/create"]}
              basename={basename}
              component={CompanyGroupAddPage}
            />
            <ConsultantRoute
              exact
              path={["/consultant/company_groups/:company_group_id", "/super_consultant/company_groups/:company_group_id"]}
              basename={basename}
              component={CompanyGroupEditPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/companies/new/:id_in_provider_system_and_companies_house_number',
                '/super_consultant/companies/new/:id_in_provider_system_and_companies_house_number'
              ]}
              basename={basename}
              component={NewCompanySelectClaimsPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/companies/:company_id/:claim_group_id/:type/:page',
                '/super_consultant/companies/:company_id/:claim_group_id/:type/:page'
              ]}
              basename={basename}
              component={AllSumPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/companies/:id/edit',
                '/super_consultant/companies/:id/edit'
              ]}
              basename={basename}
              component={CompanyEditPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/companies/:company_id/master',
                '/super_consultant/companies/:company_id/master'
              ]}
              basename={basename}
              component={ClaimGroupMasterPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/companies/:company_id/:claim_group_step',
                '/super_consultant/companies/:company_id/:claim_group_step'
              ]}
              basename={basename}
              component={ClaimGroupOverviewPage}
            />

            <ConsultantRoute
              exact
              path={[
                '/consultant/companies/:company_id/:claim_group_id/master',
                '/super_consultant/companies/:company_id/:claim_group_id/master'
              ]}
              basename={basename}
              component={ClaimGroupMasterPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/companies/:company_id/:claim_group_id/:claim_group_step',
                '/super_consultant/companies/:company_id/:claim_group_id/:claim_group_step'
              ]}
              basename={basename}
              component={ClaimGroupOverviewPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claimGroupId/project_overview',
                '/super_consultant/claim_groups/:claimGroupId/project_overview'
              ]}
              basename={basename}
              component={ProjectOverviewPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claimGroupId/technical_proof/:page',
                '/super_consultant/claim_groups/:claimGroupId/technical_proof/:page'
              ]}
              basename={basename}
              component={TechnicalProofPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id',
                '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id'
              ]}
              basename={basename}
              component={ClaimProjectReportDetailPage}
            />
            { ['/be', '/uk'].includes(process.env.PUBLIC_URL) &&
              <ConsultantRoute
                exact
                path="/consultant/documents"
                basename={basename}
                component={DocumentsPage}
              />
            }
            {process.env.PUBLIC_URL === '/it' && (
              <ConsultantRoute
                exact
                path='/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/documentation/:documentation_type'
                basename={basename}
                component={ClaimProjectReportDetailPage}
              />
            )}
            {process.env.PUBLIC_URL === '/it' && (
              <ConsultantRoute
                exact
                path='/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/technical_analysis/'
                basename={basename}
                component={ClaimProjectReportDetailPage}
              />
            )}
            {process.env.PUBLIC_URL === '/it' && (
              <ConsultantRoute
                exact
                path='/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/self_declaration_email/'
                basename={basename}
                component={ClaimProjectReportDetailPage}
              />
            )}

            {process.env.PUBLIC_URL === '/it' && (
              <ConsultantRoute
                exact
                path='/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/payment_and_invoices/:direct_purchase_lease'
                basename={basename}
                component={ClaimProjectReportDetailPage}
              />
            )}
            { process.env.PUBLIC_URL === '/de' &&
              <ConsultantRoute
                exact
                path={[
                  '/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/first_fast_check',
                  '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/first_fast_check'
                ]}
                basename={basename}
                component={FirstFastCheckPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <ConsultantRoute
                exact
                path={[
                  '/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/creating_application/:page',
                  '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/creating_application/:page'
                ]}
                basename={basename}
                component={CreatingApplicationPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <ConsultantRoute
                exact
                path={[
                  '/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/submit_application/:page',
                  '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/submit_application/:page'
                ]}
                basename={basename}
                component={SubmitApplicationPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <ConsultantRoute
                exact
                path={[
                  '/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_project_overview/:page',
                  '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_project_overview/:page'
                ]}
                basename={basename}
                component={BsfzProjectOverviewPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <ConsultantRoute
                exact
                path={[
                  '/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/additional_informations/:id',
                  '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/additional_informations/:id',
                ]}
                basename={basename}
                component={FurtherRequestsPage}
              />
            }
            { process.env.PUBLIC_URL === '/de' &&
              <ConsultantRoute
                exact
                path={[
                  '/consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_certificates/:id',
                  '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_certificates/:id',
                ]}
                basename={basename}
                component={BsfzCertificatePage}
              />
            }
            <ConsultantRoute
              exact
              path={['/consultant/claim_groups/:claimGroupId/technical_uploads', '/super_consultant/claim_groups/:claimGroupId/technical_uploads']}
              basename={basename}
              component={TechnicalUploadsPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claim_group_id/report_templates/create',
                '/super_consultant/claim_groups/:claim_group_id/report_templates/create'
              ]}
              basename={basename}
              component={CreateClaimGroupReportTemplatePage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/report_templates/:id/edit',
                '/super_consultant/report_templates/:id/edit',
              ]}
              basename={basename}
              component={EditReportTemplatePage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claim_group_id/report_templates/:id/edit',
                '/super_consultant/claim_groups/:claim_group_id/report_templates/:id/edit'
              ]}
              basename={basename}
              component={EditClaimGroupReportTemplatePage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claimGroupId/claim_submissions',
                '/super_consultant/claim_groups/:claimGroupId/claim_submissions'
              ]}
              basename={basename}
              component={ClaimGroupClaimSubmissionsPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claimGroupId/kimble_invoices',
                '/super_consultant/claim_groups/:claimGroupId/kimble_invoices'
              ]}
              basename={basename}
              component={ClaimGroupKimbleInvoicesPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/report_templates/:report_template_id/rnd_reports',
                '/super_consultant/report_templates/:report_template_id/rnd_reports'
              ]}
              basename={basename}
              component={RndReportsPage}
            />

            <ConsultantRoute
              exact
              path={[
                '/consultant/claims/:claim_id/edit/internal_invoice_detail',
                '/super_consultant/claims/:claim_id/edit/internal_invoice_detail'
              ]}
              basename={basename}
              component={ClaimEditInternalInvoiceDetailsPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claims/:claim_id/edit/general',
                '/super_consultant/claims/:claim_id/edit/general',
              ]}
              basename={basename}
              component={ClaimEditGeneralPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/users/new',
                '/super_consultant/users/new'
              ]}
              basename={basename}
              component={CreateUserPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/release_notes',
                '/super_consultant/release_notes'
              ]}
              basename={basename}
              component={ReleasesPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/release_notes/:release_id',
                '/super_consultant/release_notes/:release_id'
              ]}
              basename={basename}
              component={NotesPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires',
                '/super_consultant/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires'
              ]}
              basename={basename}
              component={ProjectReportQuestionnairesPage}
            />
            <ConsultantRoute
              exact
              path={[
                '/consultant/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires/:id',
                '/super_consultant/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires/:id'
              ]}
              basename={basename}
              component={ProjectReportEditQuestionnairePage}
            />
            <ConsultantRoute
              exact
              path="/consultant/list_of_companies"
              basename={basename}
              component={TimeTrackingCompaniesPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/companies/:company_id/claim_groups/"
              basename={basename}
              component={TimeTrackingClaimGroupsPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/claim_groups/:claim_group_id/claim_project_reports/"
              basename={basename}
              component={TimeTrackingProjectsPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/claim_groups/:claim_group_id/claim_project_reports/:claim_project_report_id/tasks"
              basename={basename}
              component={TimeTrackingTasksPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/claim_groups/:claimGroupId/claim_project_reports/:claimProjectReportId/create_task"
              basename={basename}
              component={TimeTrackingTaskFormPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/claim_groups/:claimGroupId/claim_project_reports/:claimProjectReportId/edit_task/:taskId"
              basename={basename}
              component={TimeTrackingTaskFormPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/my_tasks/"
              basename={basename}
              component={MyTasksPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/my_tasks/claim_project_reports/:claimProjectReportId/create_task"
              basename={basename}
              component={TimeTrackingTaskFormPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/my_tasks/claim_project_reports/:claimProjectReportId/edit_task/:taskId"
              basename={basename}
              component={TimeTrackingTaskFormPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/create_project"
              basename={basename}
              component={CreateClaimProjectReportPage}
            />
            <ConsultantRoute
              exact
              path="/consultant/time_tracking/claim_groups/:claim_group_id/claim_project_reports/:claim_project_report_id"
              basename={basename}
              component={EditClaimProjectReportPage}
            />
          </Switch>
        </Suspense>
      )}
    </Router>
  );
};

export default App;
