import { CROSS_MARKET_EMAIL_DYNAMIC_FIELDS } from "@rd-web-markets/shared/dist/util/constants";
export const UTR_TEXT = 'UTR';
export const SPRINT_CLAIM_NAME = 'DE_Sprint_Claim';
export const TEMPLATE_PAGE_TITLE = 'Claim Template Listing';
export const CLAIM_GROUP_ENGAGEMENT_TITLE = 'contacts_email_kick_off_meeting_organization';
export const COMPANY_REQUIRES_COMPANIES_HOUSE_INFO = false;
export const CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS = false;
export const PROJECT_GROUP_RPOGRAM_TYPE_NAME = 'program_commercial_project';
export const EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS = [{
  field: 'accounting_period_end_date',
  title: 'Accounting Period End Date',
  editableInModal: true
}, {
  field: 'accounting_period_start_date',
  title: 'Accounting Period Start Date',
  editableInModal: true
}, {
  field: 'total_qualifying_rnd_expenditure',
  title: 'Total Qualifying RND Expenditure'
}];
export const EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS = [{
  field: 'executive_summary',
  title: 'Executive Summary'
}, {
  field: 'general_rnd_process',
  title: 'General R&D Process'
}, {
  field: 'elimination_of_uncertainty_requirement',
  title: 'Elimination of uncertainty requirement'
}, {
  field: 'process_of_experimentation_requirement',
  title: 'Process of experimentation requirement'
}, {
  field: 'technological_in_nature_requirement',
  title: 'Technological in nature requirement'
}, {
  field: 'contact_to_collaborate_on_rnd',
  title: 'Contact To Collaborate',
  editableInModal: true
}];
export const EDITOR_CLAIM_PROJECT_FIELDS = [];
export const NEW_COMPANY_NEEDS_COMPANY_NUMBER = false;
export const CLAIM_GROUP_DEFAULT_PARAMS = {
  consultants_attributes: [],
  project_methodology: 'employee_basis',
  rnd_collaborate_contacts_attributes: [],
  has_overdue_taxes: false,
  has_staff_involved_not_on_payroll: false,
  accounts_provided: false,
  tax_computations_provided: false,
  development_costs_capitalised: false,
  contracted_by_third_party: false,
  received_grants_or_subsidies: false,
  reimburses_staff_expenses: false,
  purchased_company_cars: false,
  has_patents: false
};
export const CLAIM_GROUP_EDIT_FORM_FIELDS = {
  showSicCodesString: false,
  showProjectMehotdology: false,
  showOverdueTaxesSection: false,
  showOverdueTaxesQuestion: true,
  showDoesCompanyReimburseStaffExpenses: true,
  showDoesCompanyPayCarAllowance: true
};
export const SHOULD_SHOW_COST_METHODOLOGY = false;
export const SHOULD_SHOW_OVERDUE_TAXES = false;
export const CLIENT_CONTACT_TYPES = ['Financial Contact', 'Client Sponsor', 'Contact - Elster Zertifikat', 'Contractor'];
export const EXTERNAL_CONTACT_TYPES = ['Auditor'];
export const CLAIM_GROUP_EDIT_PAGE_SHOW_YEAR_END_DROPDOWN = false;
export const CLAIM_GROUP_SHOW_ALL_PROJECT = true;
export const APP_FOOTER_TEXT = 'R&D Tax Credits Portal by Ayming';
export const APP_RELEASE_VERSION = 'Release 1.106.1';
export const LOCALE = 'de-DE';
export const ENGINE_NAME = 'DeSection';
export const EMAIL_TEMPLATE_DYNAMIC_FIELDS = {
  email_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  client_cost_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  claim_schedule_ics_event_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  self_declaration_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS
};
export const SHOW_CLAIM_GROUP_TASKS = false;
export const SHOW_COMPANY_CLAIM_GROUP_EDIT_FORM_FIELDS = true;
export const MARKET_SPECIFIC_RD_OR_4 = 'R&D';
export const MARKET_SPECIFIC_RD_OR_4_MENU = 'R&D Technical';
export const TEMPLATE_HEADER_TITLE = {
  email_template: 'Configure Kick Off Template',
  client_cost_template: 'Configure Client Cost Template',
  claim_schedule_ics_event_template: 'Configure Ics Event Template',
  self_declaration_template: 'Configure Self - Declaration Email Template',
  kick_off: 'Configure Kick Off Template'
};
export const REPORT_SAVE_PARAM = '';
export const LOCAL_LANGUAGES = ['de', 'en'];
export const REPORT_TYPE_INCLUDE_SECTION_COLUMN_NAME = {
  technical: 'include_in_technical_report'
};
export const TASK_TITLE = 'claim_group_tasks';
export const CURRENCY_SYMBOL = '€';
export const SIDEBAR_MENU_ITEMS = [{
  text: 'project_overview',
  link: 'project_overview'
}, {
  text: 'edit_claim_info',
  link: 'edit/general'
}, {
  text: 'engagement_team',
  link: 'engagement_team'
}, {
  text: 'intro_email',
  link: 'intro_email'
}];
export const SIDEBAR_NAV_ADMIN_LINKS = [{
  text: 'companies',
  link: 'companies'
}, {
  text: 'claim_groups',
  link: 'claims',
  subMenu: [{
    text: 'my_claim_groups',
    link: 'my_claim_groups'
  }, {
    text: 'all_claim_groups',
    link: 'all_claim_groups'
  }]
}, {
  text: 'users',
  link: 'users'
}, {
  text: 'templates',
  link: 'templates',
  subMenu: [{
    text: 'project_progress_template',
    link: 'claim_template'
  }, {
    text: 'rd_report_template',
    link: 'report_templates'
  }, {
    text: 'kick_off_email_template',
    link: 'kickoff_email_template'
  }, {
    text: 'cliam_schedule_ics_event_template',
    link: 'claim_schedule_ics_event_template'
  }, {
    text: 'client_cost_template',
    link: 'client_cost_template'
  }, {
    text: 'faq',
    link: 'faq_template'
  }]
}, {
  text: 'translations',
  link: 'translations'
}];
export const SIDEBAR_NAV_CLIENT_LINKS = [{
  text: 'All Claims',
  link: ''
}];
export const SIDEBAR_NAV_ACCOUNTANT_LINKS = [{
  text: 'All Claims',
  link: ''
}];
export const SIDEBAR_NAV_CONSULTANT_LINKS = [{
  text: 'companies',
  link: 'companies'
}, {
  text: 'Claims',
  link: 'my_claim_groups'
}];
export const SIDEBAR_NAV_SUPER_CONSULTANT_LINKS = [{
  text: 'Companies',
  link: 'companies'
}, {
  text: 'claim_groups',
  link: 'my_claim_groups',
  subMenu: [{
    text: 'my_claim_groups',
    link: 'my_claim_groups'
  }, {
    text: 'all_claim_groups',
    link: 'all_claim_groups'
  }]
}];
export const SIDEBAR_CLAIM_COSTS_LINKS = [{
  text: 'Upload/Download Cost Template',
  link: 'financial_details/upload'
}, {
  text: 'Cost Categories',
  link: 'financial_details/general'
}, {
  text: 'Client Cost Email',
  link: 'financial_details/email'
}, {
  text: 'Edit Claim',
  link: 'edit/general'
}, {
  text: 'Manager Cost Review',
  link: 'financial_details/manager_review'
}];
export const SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER = [{
  text: 'Upload/Download Cost Template',
  link: 'financial_details/upload'
}, {
  text: 'Cost Categories',
  link: 'financial_details/general'
}];
export const SIDEBAR_CLAIM_COSTS_LINKS_ACCOUNTANT = [{
  text: 'Cost Categories',
  link: 'financial_details/general'
}];
export const SIDEBAR_CLAIM_OVERVIEW_LINKS_CUSTOMER = [{
  text: 'Claim Cost',
  link: 'claim_cost',
  subMenu: true
}, {
  text: 'R&D Technical',
  link: 'technical_section'
}];
export const SIDEBAR_NAV_RND_REPORT_PAGE_CLIENT_LINKS = {
  link: 'companies',
  text: 'Companies'
};
export const CURRENCY_FORMATTER = new Intl.NumberFormat(LOCALE);
export const PERCENTAGE_FORMATTER = new Intl.NumberFormat(LOCALE, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});
export const ENGAGEMENT_TEAM_ROLES = [{
  value: 'client_sponsor'
}, {
  value: 'financial_contact'
}, {
  value: 'contact_elster_zertifikat'
}, {
  value: 'contractor'
}, {
  value: 'project_manager'
}, {
  value: 'project_member'
}];
export const CLIENT_RND_EDIT_DYNAMIC_FIELD = true;
export const CLIENT_RND_SELECT_PROJECT_POPOVER = true;
export const SUPPORT_EMAIL_ADDRESS = 'de.support.advance@ayming.com';
export const ATTACHMENT_OPTIONS = {
  attachCostTemplate: false,
  technicalTemplateField: false,
  includeTechnicalUploadField: false,
  includeCostTemplateLink: false
};
export const TEMPLATE_SELECT_MODEL_NAME = 'REPLACE_IF_NEEDED'; //so the build would not fail other wise not needed yet
export const PROJECT_FIELD_MODEL_NAME = 'REPLACE_IF_NEEDED'; //so the build would not fail other wise not needed yet
export const SHOULD_CREATE_REPORT = false; //so the build would not fail other wise not needed yet

export const modifyReportTemplateCategoriesForProject = [0, 0]; //at index 0, remove no element

export const CONSULTANT_ROLE_NAME = 'super_consultant';
export const SHOW_DEPARTMENT_FOR_USER_FORM = true;
export const SHOULD_PROJECT_POPOVER_EXCLUDE_OTHER_PROJECTS = false;
export const TINYMCE_MARKET_REPORT_MODULES = ['styles_formatter', 'track_changes', 'comments', 'resize_image_when_added'];
export const TINYMCE_MARKET_EMAIL_MODULES = ['styles_formatter', 'resize_image_when_added'];
export const TINYMCE_MARKET_OTHER_MODULES = ['styles_formatter', 'track_changes', 'comments', 'resize_image_when_added'];
export const SIDEBAR_TECHNICAL_SECTION_LINKS = []; // needed for build
export const SHOW_COST_MENU_IN_MASTER = true;
export const SHOW_DOWNLOAD_TEMPLATE_BUTTON = true;
export const SHOW_SIDE_BAR_IN_CLAIM_GROUP_OVERVIEW = false;
export const FINANCIAL_SECTION_GENERAL_LINK = 'general';
export const SHOULD_AUTOSAVE = false;
export const SHOULD_PRESET_COMPANY_CLAIMGROUP_FORM_FILEDS = false;
export const SHOULD_SHOW_ADD_USER_BUTTON_FOR_ADMINS = false;
export const SHOULD_SHOW_ADMIN_OPTION_FOR_CONSULTANTS = true;
export const IS_PROJECT_DATE_SHORT_FORMAT = false;
export const SHOULD_SHOW_DATE_IN_SUMMARY_SECTION = false;
export const RATES_SCHEME = {
  'Enhancement Rates': ['rate'],
  'Credit Rates': ['rate'],
  'Corporation Tax Rates': ['rate'],
  'NI Rates': ['rate'],
  'NI Thresholds': ['threshold']
};
export const SHOULD_ADD_REPORT_TEMPLATE_SEARCH = false;
export const SHOULD_SHOW_TEMPLATE_TITLE = false;
export const SHOW_SEND_CLAIM_BUTTON = false;
export const PROJECT_REPORT_DATE_PICKER_TYPE = 'date';
export const SHOW_CLAIM_GROUP_PROJECT_ELSTER = true;
export const SHOW_PROJECT_TEMPLATE_SELECT = false;
export const SHOW_IT_TYPE_SELECT = false;
export const SHOW_PROJECT_GROUPING_TAB_ON_PROJECT_OVERVIEW_PAGE = false;
export const SHOW_MARKET_PROGRAM_LIST = false;
export const PROJECT_OVERVIEW_TABLE = {
  headers: [{
    text: 'name',
    sortable: true,
    orderBy: 'project_name',
    filterableByText: true,
    filterBy: 'project_name'
  }, {
    text: 'project_status',
    sortable: true,
    orderBy: 'project_status',
    filterableByText: true,
    filterBy: 'project_status'
  }, {
    text: 'documents'
  }, {
    text: ''
  }, {
    text: ''
  }],
  columns: ['project_name', 'completion_status', 'documents', '', 'deleteButton']
};
export const SHOW_QUESTIONNAIRES_SELECT_SEPARATELY = true;
export const SHOW_REGULAR_REALTIME_DASHBOARD = false;
export const SHOW_ADD_CLAIM_GROUP_BTN_ON_MASTER_PAGE = true;
export const SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR = true;
export const SHOULD_SHOW_ADD_COMPANY_BUTTON = true;
export const SHOULD_SHOW_ADD_USERS_TO_COMPANY = false;
export const ADD_NEW_USER_TO_COMPANY = false;
export const ACCOUNT_TYPES_THAT_CAN_ADD_USER_TO_COMPANY = ['customer', 'accountant'];
export const SHOULD_SHOW_CREATE_PROJECTS_PAGE_FOR_CUSTOMER = false;
export const SHOW_EDIT_BUTTON_FOR_RESTRICTED_FOLDER = true;
export const FILE_MANAGEMENT_COLUMN_ORDER = ['folderIcon', 'createdAt', 'name', 'notes', 'uploadedBy', 'editButton'];
export const PROJECT_OVERVIEW_ALL_CONTACTS_MAX_AMOUNT_OF_PROJECT_BADGES = Number.MAX_VALUE;
export const DASHBOARD_PAGE_SIDE_BAR_MENU_ITEMS = {
  link: 'dashboards',
  text: 'Dashboards'
};
export const SIDEBAR_MENU_ITEMS_CUSTOMER = [{
  text: 'project_overview',
  link: 'project_overview'
}, {
  text: 'engagement_team',
  link: 'engagement_team'
}];
export const SHOW_COMPANY_GROUPS_HEADER = true;
export const SHOW_ADD_PROJECTS_BUTTON_ON_MASTER_PAGE = true;
export const SHOW_IMPORT_PROJECT_AT_PROJECT_OVERVIEW = false;
export const SHOW_SEND_QUESTIONNAIRE_BUTTON_ON_PROJECT_OVERVIEW_PAGE = false;
export const SHOW_PROJECT_QUESTIONNAIRE_BUTTON_ON_PROJECT_OVERVIEW_PAGE = false;
export const SHOW_QUESTIONNAIRES_TAB_IN_PROJECT_OVERVIEW = false;
export const SHOW_FAQ_ENTRY_HEADER = true;